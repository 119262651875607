.editor_wrap {
    padding: 0 0 32px 0;
    /* height: -webkit-fill-available; */
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.text {
    width: 70%;
    margin-bottom: 4px;
}
