.option {
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
}

.option > button > span {
    font-weight: 500;
    text-align: left;
    font-size: 12px;
    line-height: 20px;
}

.option > button {
    padding: 0 24px 0 0;
    height: 100%;
}
