.tabs {
    position: relative;
    width: 100%;
    overflow: auto;
}

.tabs_wrapper {
    display: flex;
    overflow: visible;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--color-border-primary);
    background-color: var(--color-bg-primary);
    margin-bottom: 20px;
    position: sticky;
    top: 0;
    z-index: 80;
    gap: 20px;
}

.tab {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
    position: relative;
    padding: 0px 0 12px;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
}

.tab:hover > * {
    color: var(--color-text-primary) !important;
}

.tab:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}

.tab.active:after {
    background-color: var(--color-border-accent);
}

.tab_content {
    /* padding-top: 56px; */
}
