.row > * {
    flex-shrink: 0;
}

.row {
    height: 30px;
}

.title {
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 2;
    padding: 3px 0;
    width: 170px;
}