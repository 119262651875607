.option {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: left;
}

.picker {
    z-index: 2;
}

.option button {
    font-weight: 500;
    text-align: left;
    font-size: 12px;
    justify-content: flex-start;
    width: 100%;
}

.option button > span {
    text-align: left;
}
